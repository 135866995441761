<template>
  <div class="container body">
    <div class="album-list">
      <h1>Albums</h1>

      <loading-indicator />

      <div class="row row-cols-1 row-cols-md-4">
        <div class="col mb-4" v-for="album in albums" :key="album.slug">
          <div class="card">
            <router-link :to="{ name: 'viewAlbum', params: { slug: album.slug } }">
              <img v-if="album.coverPhotoUrl" :src="album.coverPhotoUrl" class="card-img-top">
              <img v-else src="https://via.placeholder.com/350x150" class="card-img-top">
            </router-link>
            <div class="card-body">
              <h5 class="card-title">
                <router-link :to="{ name: 'viewAlbum', params: { slug: album.slug } }" class="text-reset">
                  {{ album.name }}
                </router-link>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import LoadingIndicator from '../../components/LoadingIndicator.vue';

export default {
  components: {
    LoadingIndicator,
  },
  computed: {
    ...mapFields('albums', [
      'albums',
    ]),
  },
  async created() {
    await this.loadAlbums();
  },
  methods: {
    ...mapActions('albums', [ 'loadAlbums' ])
  },
  metaInfo: () => ({
    title: 'Albums',
  }),
}
</script>
